import React from "react";
import { decode as base64UrlDecode } from "universal-base64url";
import { Admin } from "./components/5-pages/Admin";
import { Event1 } from "./components/5-pages/Event1";
import { Event2 } from "./components/5-pages/Event2";
import Home from "./Home";
import { EviteData } from "./model/EviteData";
import { ViewData } from "./model/ViewData";
import View from "./View";

function App() {
  const params = new URLSearchParams(window.location.search);

  const m = params.get("m");
  if (m) {
    const eviteData = JSON.parse(base64UrlDecode(m)) as EviteData;
    window.document.title = "Save the date | Eva & Charlie";
    return <Event1 data={eviteData} />;
  }

  const w = params.get("w");
  if (w) {
    const eviteData = JSON.parse(base64UrlDecode(w)) as EviteData;
    window.document.title = "Save the date | Eva & Charlie";
    return <Event2 data={eviteData} />;
  }

  const v = params.get("v");
  if (v) {
    const viewData = JSON.parse(base64UrlDecode(v)) as ViewData;
    window.document.title = "Save the date | Eva & Charlie";
    return <View data={viewData}></View>;
  }

  const a = params.get("a");
  const k = params.get("k") || undefined;
  if (a) {
    window.document.title = "Admin | Eva & Charlie";
    return <Admin token={a} id={k}></Admin>;
  }

  return <Home></Home>;
}

export default App;
