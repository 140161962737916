export function Separator(props: { color: string }) {
  return (
    <div
      className="Separator"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "15px 0",
      }}
    >
      <div style={{ height: 1, backgroundColor: props.color, width: 50 }} />
    </div>
  );
}
