import { EviteData } from "../../model/EviteData";
import { defaultTheme } from "../../themes/defaultTheme";
import { Separator } from "../1-atoms/Separator";
import { Span } from "../1-atoms/Span";
import { Paragraph } from "../2-molecules/Paragraph";
import { Event } from "../4-templates/Event";

export function Event2(props: { data: EviteData }) {
  return (
    <Event
      data={props.data}
      theme={defaultTheme}
      title="Notre Union"
      backgroundImage="url(/pexels-4.jpg)"
      // backgroundImage="url(/lux.jpeg)"
    >
      <Separator color={defaultTheme.textColorLighter} />
      <Paragraph>Samedi 20 Avril 2024</Paragraph>
      <Separator color={defaultTheme.textColorLighter} />
      <Paragraph fontWeight={700}>LUX Saint Gilles</Paragraph>
      <Paragraph fontStyle="italic">Ile de La Réunion</Paragraph>
    </Event>
  );
}
