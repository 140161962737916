import "./MaterialIcon.css";

export function MaterialIcon(props: {
  codePoint: number;
  color?: string;
  size?: number | string;
}) {
  return (
    <span
      className="MaterialIcon"
      style={{
        color: props.color,
        fontSize: props.size,
      }}
    >
      {String.fromCodePoint(props.codePoint)}
    </span>
  );
}
