import React from "react";
import { useMediaQuery } from "react-responsive";
import { metrics, Metrics } from "../../functions/metrics";
import { defaultTheme } from "../../themes/defaultTheme";
import { MaterialIcon } from "../1-atoms/MaterialIcon";
import { Span } from "../1-atoms/Span";
import { Paragraph } from "../2-molecules/Paragraph";
import { Card } from "../3-organisms/Card";

export function Admin(props: { token: string; id?: string }) {
  const isSmall = useMediaQuery({ query: `(max-width: 820px)` });
  const [loading, setLoading] = React.useState(false);
  const [metricsData, setMetricsData] = React.useState(null as Metrics | null);

  React.useEffect(() => {
    async function loadMetrics(token: string) {
      setLoading(true);
      try {
        setMetricsData(await metrics(token));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    loadMetrics(props.token);
  }, [props.token]);

  return (
    <div
      className="Admin"
      style={{
        alignItems: "stretch",
        backgroundColor: defaultTheme.textColor,
        color: defaultTheme.backgroundColor,
        display: "flex",
        flexDirection: isSmall ? "column" : "row",
        fontFamily: defaultTheme.serifFontFamily,
        fontWeight: defaultTheme.serifFontWeight,
        justifyContent: "center",
        minHeight: "100%",
      }}
    >
      <Card gap={10}>
        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>Invitations</Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.eviteCount : "..."}
          </Paragraph>
        </Card>

        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>Réponses</Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.responseCount : "..."}
          </Paragraph>
        </Card>

        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>Oui</Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.yesCount : "..."}
          </Paragraph>
        </Card>

        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>Non</Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.noCount : "..."}
          </Paragraph>
        </Card>

        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>Adultes OUI</Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.adultsYesCount : "..."}
          </Paragraph>
        </Card>

        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>
            Adultes OUI depuis 1er décembre
          </Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.adultsYesAfter1December2023 : "..."}
          </Paragraph>
        </Card>

        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>Enfants OUI</Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.childrenYesCount : "..."}
          </Paragraph>
        </Card>

        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>Adultes OUI ou ?</Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.adultsYesOrUnknownCount : "..."}
          </Paragraph>
        </Card>

        <Card
          backgroundColor={defaultTheme.backgroundColorLighter}
          borderRadius={25}
          color={defaultTheme.backgroundColor}
        >
          <Paragraph fontWeight={900}>Enfants OUI ou ?</Paragraph>
          <Paragraph
            color={defaultTheme.textColorLighter}
            fontFamily={defaultTheme.monoFontFamily}
            fontWeight={defaultTheme.monoFontWeight}
          >
            {metricsData ? metricsData.childrenYesOrUnknownCount : "..."}
          </Paragraph>
        </Card>
      </Card>

      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          fontSize: "0.85em",
          gap: 15,
          justifyContent: "flex-start",
          padding: 15,
        }}
      >
        {metricsData?.items.map((item) => (
          <div
            key={item.id}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              gap: 10,
              justifyContent: "center",
            }}
          >
            <MaterialIcon
              size={24}
              codePoint={
                item.response && item.response.y
                  ? 0xe8dc
                  : item.response && item.response.n
                  ? 0xe8db
                  : 0xe8b5
              }
            />

            <div style={{}}>
              <Paragraph fontWeight={900}>
                <a
                  href={item.url}
                  target="_blank"
                  style={{
                    color: defaultTheme.linkColor,
                    textDecoration: "none",
                  }}
                >
                  {item.evite.gn}
                </a>
              </Paragraph>
              <Paragraph fontSize="0.9em">
                Générée le{" "}
                <Span
                  color={defaultTheme.textColorLighter}
                  fontFamily={defaultTheme.monoFontFamily}
                >
                  {new Date(item.evite.t).toLocaleDateString()}
                </Span>
              </Paragraph>
              <Paragraph fontSize="0.9em">
                {item.response && (
                  <React.Fragment>
                    Réponse le{" "}
                    <Span
                      color={defaultTheme.textColorLighter}
                      fontFamily={defaultTheme.monoFontFamily}
                    >
                      {new Date(item.response.t).toLocaleDateString()}
                    </Span>
                  </React.Fragment>
                )}
                {!item.response && "Pas de réponse"}
              </Paragraph>
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
}
