import React from "react";
import { EviteData } from "../../model/EviteData";
import { defaultTheme } from "../../themes/defaultTheme";
import { Event } from "../4-templates/Event";
import { Separator } from "../1-atoms/Separator";
import { Paragraph } from "../2-molecules/Paragraph";

export function Event1(props: { data: EviteData }) {
  return (
    <Event
      data={props.data}
      theme={defaultTheme}
      title="Notre union civile"
      backgroundImage="url(/pexels-1.jpg)"
    >
      {(props.data.eid === 1 || props.data.eid === 2) && (
        <React.Fragment>
          <Separator color={defaultTheme.textColorLighter} />
          <Paragraph>10h00 : Cérémonie</Paragraph>
          <Paragraph fontStyle="italic">Mairie de Lyon 3</Paragraph>
          <Paragraph fontStyle="italic">
            215 rue Duguesclin, 69003 Lyon
          </Paragraph>
        </React.Fragment>
      )}

      {props.data.eid === 2 && (
        <React.Fragment>
          <Separator color={defaultTheme.textColorLighter} />
          <Paragraph>13h00 : Repas et festivités</Paragraph>
          <Paragraph fontStyle="italic">Maison de Cologny</Paragraph>
        </React.Fragment>
      )}
    </Event>
  );
}
