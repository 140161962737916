import { ViewData } from "./model/ViewData";

export default function View(props: { data: ViewData }) {
  const date = new Date(props.data.r.t);

  return (
    <div className="View">
      <div className="Card">
        <h3>
          {props.data.e.gn !== "" && <div>{props.data.e.gn}</div>}

          {!props.data.e.gn &&
            props.data.e.a.map((a, i) => (
              <div key={i}>
                {a.fn.toLowerCase()} {a.ln.toLowerCase()}
              </div>
            ))}
        </h3>
        {props.data.r.y && (
          <h2>
            <b>Oui</b>, nous serons présents
          </h2>
        )}
        {props.data.r.n && (
          <h2>
            <b>Non</b>, nous ne serons pas présents
          </h2>
        )}
        <table>
          <tr>
            <th>Date réponse :</th>
            <td>{date.toLocaleDateString()}</td>
          </tr>
          <tr>
            <th>Heure réponse :</th>
            <td>{date.toLocaleTimeString()}</td>
          </tr>
          <tr>
            <th>ID Groupe :</th>
            <td>{props.data.e.gid}</td>
          </tr>
          <tr>
            <th>Adultes :</th>
            <td>{props.data.r.a}</td>
          </tr>
          <tr>
            <th>Enfants :</th>
            <td>{props.data.r.c}</td>
          </tr>
          <tr>
            <th>Bambins :</th>
            <td>{props.data.r.b}</td>
          </tr>
        </table>
      </div>
    </div>
  );
}
