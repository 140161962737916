import { Theme } from "./Theme";

export const defaultTheme: Theme = {
  backgroundColor: "rgb(250,250,250)",
  backgroundColorLight: "rgb(82,82,82)",
  backgroundColorLighter: "rgba(37,37,37)",
  cursiveFontFamily: '"Alex Brush", cursive',
  cursiveFontSize: "1.5em",
  cursiveFontWeight: 700,
  linkColor: "rgb(150, 200, 250)",
  monoFontFamily: "PT Mono, monospace",
  monoFontWeight: 400,
  serifFontFamily: "Quicksand, serif",
  serifFontWeight: 300,
  textColor: "rgb(5,5,5)",
  textColorLight: "rgb(168,168,168)",
  textColorLighter: "rgba(213,213,213)",
};
