import { EviteData } from "../model/EviteData";
import { ResponseData } from "../model/ResponseData";
import { UserFriendlyError } from "./UserFriendlyError";

export async function save(
  eviteData: EviteData,
  yesChecked: boolean,
  noChecked: boolean,
  numberOfAdults?: number,
  numberOfChildren?: number,
  numberOfBabies?: number
): Promise<void> {
  const apiUrl = process.env.REACT_APP_FUNCTIONS_URL;
  const fetchMode = process.env.REACT_APP_FUNCTIONS_MODE as RequestMode;

  const response = await fetch(`${apiUrl}/save`, {
    method: "POST",
    mode: fetchMode,
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      e: eviteData,
      r: {
        y: yesChecked,
        n: noChecked,
        a: numberOfAdults,
        c: numberOfChildren,
        b: numberOfBabies,
      } as ResponseData,
    }),
  });

  if (response.status === 403) {
    throw new UserFriendlyError(
      "Cette invitation n’est plus valide.\r\nEn avez-vous reçu une plus récente ?"
    );
  }

  if (response.status !== 200) {
    throw new Error(`Unexpected response from save function: ${response}`);
  }
}
