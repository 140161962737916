export interface SpanProps {
  children?: React.ReactNode;
  color?: string;
  fontFamily?: string;
  fontSize?: string | number;
  fontStyle?: string;
  fontWeight?: number;
  textDecoration?: string;
}

export function Span(props: SpanProps) {
  return (
    <span
      style={{
        color: props.color,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        fontStyle: props.fontStyle,
        fontWeight: props.fontWeight,
        textDecoration: props.textDecoration,
      }}
    >
      {props.children}
    </span>
  );
}
