export interface Metrics {
  responseCount: number;
  yesCount: number;
  noCount: number;
  civCount: number;
  civColCount: number;
  eviteCount: number;
  adultsYesCount: number;
  childrenYesCount: number;
  adultsYesOrUnknownCount: number;
  childrenYesOrUnknownCount: number;
  adultsYesAfter1December2023: number;
  items: Item[];
}

interface Item {
  id: string;
  url: string;
  evite: {
    gn: string;
    eid: number;
    t: number;
    a?: {}[];
    c?: number;
  };
  response: {
    y: boolean;
    n: boolean;
    t: number;
  };
}

export async function metrics(token: string): Promise<Metrics> {
  const apiUrl = process.env.REACT_APP_FUNCTIONS_URL;
  const fetchMode = process.env.REACT_APP_FUNCTIONS_MODE as RequestMode;

  const response = await fetch(`${apiUrl}/metrics`, {
    method: "POST",
    mode: fetchMode,
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  });

  if (response.status !== 200) {
    throw new Error(`Unexpected response from API: ${response}`);
  }

  const items = (await response.json()) as Item[];
  const yesData = items.filter((x) => x.response && x.response.y);
  const yesOrUnknownData = items.filter(
    (x) => (x.response && x.response.y) || !x.response
  );
  const noData = items.filter((x) => x.response && x.response.n);
  const tMin = new Date(2023, 11, 1).valueOf();

  return {
    adultsYesAfter1December2023: yesData.reduce(
      (prev, curr) =>
        prev +
        (curr.response && curr.response.t && curr.response.t >= tMin
          ? curr.evite.a?.length || 1
          : 0),
      0
    ),
    adultsYesCount: yesData.reduce(
      (prev, curr) => prev + (curr.evite.a?.length || 1),
      0
    ),
    childrenYesCount: yesData.reduce(
      (prev, curr) => prev + (curr.evite.c || 0),
      0
    ),
    adultsYesOrUnknownCount: yesOrUnknownData.reduce(
      (prev, curr) => prev + (curr.evite.a?.length || 1),
      0
    ),
    childrenYesOrUnknownCount: yesOrUnknownData.reduce(
      (prev, curr) => prev + (curr.evite.c || 0),
      0
    ),
    civColCount: yesData.filter((x) => x.evite && x.evite.eid === 2).length,
    civCount: yesData.filter((x) => x.evite && x.evite.eid === 1).length,
    eviteCount: items.length,
    items,
    responseCount: yesData.length + noData.length,
    noCount: noData.length,
    yesCount: yesData.length,
  };
}
