import React from "react";
import { Theme } from "../../themes/Theme";

export interface ButtonProps {
  children?: React.ReactNode;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: number | string;
  borderStyle?: string;
  borderWidth?: number;
  color?: string;
  disabled?: boolean;
  height?: number;
  focusBackgroundColor?: string;
  focusBorderColor?: string;
  fontFamily?: string;
  fontSize?: number | string;
  fontWeight?: number;
  mouseOverBackgroundColor?: string;
  mouseOverBorderColor?: string;
  onClick?: () => void;
  opacity?: number | string;
  scrollIntoView?: boolean;
  theme?: Theme;
  width?: number;
}

export function Button(props: ButtonProps) {
  const [hasMouseOver, setMouseOver] = React.useState(false);
  const [hasFocus, setFocus] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (props.scrollIntoView) {
      setTimeout(
        () =>
          buttonRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          }),
        500
      );
    }
  }, [buttonRef, props.scrollIntoView]);

  return (
    <button
      ref={buttonRef}
      type="button"
      style={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        gap: 5,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          (hasFocus && !props.disabled
            ? props.focusBackgroundColor
            : hasMouseOver && !props.disabled
            ? props.mouseOverBackgroundColor
            : undefined) ||
          props.backgroundColor ||
          "transparent",
        borderColor:
          (!props.disabled && hasFocus
            ? props.focusBorderColor
            : !props.disabled && hasMouseOver
            ? props.mouseOverBorderColor
            : undefined) ||
          props.borderColor ||
          props.theme?.textColorLighter ||
          undefined,
        borderRadius: props.borderRadius || 5,
        borderStyle: props.borderStyle || "solid",
        borderWidth:
          (props.borderWidth || 0.5) *
          (!props.disabled && (hasMouseOver || hasFocus) ? 1.5 : 1),
        color: props.color,
        cursor: props.disabled ? undefined : "pointer",
        fontFamily:
          props.fontFamily || props.theme?.serifFontFamily || undefined,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight || 400,
        height: props.height,
        opacity: props.disabled ? 0.5 : props.opacity,
        width: props.width,
      }}
      disabled={props.disabled}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onClick={() => {
        if (props.onClick && !props.disabled) props.onClick();
      }}
    >
      {props.children}
    </button>
  );
}
