export function Card(props: {
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: number | string;
  borderStyle?: string;
  borderWidth?: number | string;
  children?: React.ReactNode;
  color?: string;
  gap?: number;
  width?: number;
  height?: number;
}) {
  return (
    <div
      className="Card"
      style={{
        backgroundColor: props.backgroundColor,
        boxSizing: "border-box",
        borderColor: props.borderColor,
        borderRadius: props.borderRadius,
        borderStyle: props.borderStyle,
        borderWidth: props.borderWidth,
        color: props.color,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: props.gap || 5,
        textAlign: "center",
        padding: "20px 10px",
        cursor: "default",
        width: props.width,
        height: props.height,
      }}
    >
      {props.children}
    </div>
  );
}
