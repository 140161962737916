import { Theme } from "../../themes/Theme";
import { Paragraph, ParagraphProps } from "./Paragraph";

export function CursiveParagraph(props: ParagraphProps & { theme: Theme }) {
  return (
    <Paragraph
      fontFamily={props.theme.cursiveFontFamily}
      fontSize={props.theme.cursiveFontSize}
      fontWeight={props.theme.cursiveFontWeight}
      {...props}
    />
  );
}
