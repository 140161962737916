export default function Home() {
  return (
    <div
      style={{
        fontFamily: "fa-solid",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "rgba(250, 250, 250, 95%)",
        fontSize: "60px",
        height: "100%",
        background: "rgba(50, 26, 8, 95%)",
        cursor: "default",
      }}
    >
      <span
        style={{
          letterSpacing: "10px",
          padding: "20px",
          textAlign: "center",
        }}
      >
        &#xf4be; &#xf3a5; &#xf584; &#xf7a2; &#xf072; &#xf57c; &#xf0c1; &#xf79f;
      </span>
    </div>
  );
}
