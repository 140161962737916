import React from "react";
import { useMediaQuery } from "react-responsive";
import { save } from "../../functions/save";
import { UserFriendlyError } from "../../functions/UserFriendlyError";
import { EviteData } from "../../model/EviteData";
import { Theme } from "../../themes/Theme";
import { Button } from "../1-atoms/Button";
import { MaterialIcon } from "../1-atoms/MaterialIcon";
import { Separator } from "../1-atoms/Separator";
import { Span } from "../1-atoms/Span";
import { CursiveParagraph } from "../2-molecules/CursiveParagraph";
import { Paragraph } from "../2-molecules/Paragraph";
import { Card } from "../3-organisms/Card";

export function Event(props: {
  data: EviteData;
  theme: Theme;
  title: string;
  backgroundImage: string;
  children: React.ReactNode;
}) {
  const isSmall = useMediaQuery({ query: `(max-width: 820px)` });
  const [yesChecked, setYesChecked] = React.useState(false);
  const [noChecked, setNoChecked] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  return (
    <div
      className="Event"
      style={{
        minHeight: "100%",
        display: "flex",
        flexDirection: isSmall ? "column" : "row",
        justifyContent: "center",
        alignItems: isSmall ? "stretch" : "center",
        overflow: "auto",
        fontFamily: props.theme.serifFontFamily,
        fontWeight: props.theme.serifFontWeight,
        backgroundImage: props.backgroundImage,
        //backgroundClip: "content-box",
        backgroundRepeat: "repeat",
        backgroundSize: "100%",
      }}
    >
      <Card
        backgroundColor={props.theme.backgroundColor}
        color={props.theme.textColor}
        width={isSmall ? undefined : 410}
        height={isSmall ? undefined : 500}
      >
        <Paragraph>A l’attention de</Paragraph>
        <CursiveParagraph theme={props.theme}>
          {props.data.gn && <Span>{props.data.gn}</Span>}

          {!props.data.gn &&
            props.data.a.map((a, i) => (
              <Span key={i}>
                {a.fn.toLowerCase()} {a.ln.toLowerCase()}
              </Span>
            ))}
        </CursiveParagraph>
        <Separator color={props.theme.textColorLighter} />
        <Paragraph>C’est avec honneur et plaisir que</Paragraph>
        <Paragraph>Nous vous invitons à célébrer</Paragraph>
        <Paragraph>{props.title}</Paragraph>

        {props.children}

        <div style={{ flexGrow: 1 }} />
        <Separator color={props.theme.textColorLighter} />
        <CursiveParagraph theme={props.theme}>
          Eva &amp; Charlie
        </CursiveParagraph>
      </Card>
      <Card
        backgroundColor={props.theme.textColor}
        color={props.theme.backgroundColor}
        width={isSmall ? undefined : 410}
        height={isSmall ? undefined : 500}
      >
        <CursiveParagraph theme={props.theme}>Votre réponse</CursiveParagraph>
        <Separator color={props.theme.backgroundColorLighter} />
        {!saved && (
          <React.Fragment>
            <Button
              theme={props.theme}
              width={280}
              height={25}
              backgroundColor={props.theme.backgroundColorLighter}
              borderColor={props.theme.backgroundColorLight}
              color={props.theme.backgroundColor}
              focusBackgroundColor={props.theme.backgroundColorLight}
              focusBorderColor={props.theme.backgroundColor}
              mouseOverBackgroundColor={props.theme.backgroundColorLight}
              mouseOverBorderColor={props.theme.backgroundColor}
              onClick={() => {
                setYesChecked(true);
                setNoChecked(false);
              }}
              opacity={noChecked ? 0.75 : 1}
            >
              <MaterialIcon
                codePoint={yesChecked ? 0xe837 : 0xe836}
                size={24 * 0.75}
              />
              <Span textDecoration={noChecked ? "line-through" : undefined}>
                <Span fontWeight={900}>Oui,</Span>&nbsp;nous serons présents
              </Span>
            </Button>
            <Button
              theme={props.theme}
              width={280}
              height={25}
              backgroundColor={props.theme.backgroundColorLighter}
              borderColor={props.theme.backgroundColorLight}
              color={props.theme.backgroundColor}
              focusBackgroundColor={props.theme.backgroundColorLight}
              focusBorderColor={props.theme.backgroundColor}
              mouseOverBackgroundColor={props.theme.backgroundColorLight}
              mouseOverBorderColor={props.theme.backgroundColor}
              onClick={() => {
                setYesChecked(false);
                setNoChecked(true);
              }}
              opacity={yesChecked ? 0.75 : 1}
            >
              <MaterialIcon
                codePoint={noChecked ? 0xe837 : 0xe836}
                size={24 * 0.75}
              />
              <Span textDecoration={yesChecked ? "line-through" : undefined}>
                <Span fontWeight={900}>Non,</Span>&nbsp;nous ne serons pas
                présents
              </Span>
            </Button>
          </React.Fragment>
        )}

        {!saved && (yesChecked || noChecked) && (
          <React.Fragment>
            <Separator color={props.theme.backgroundColorLighter} />
            <Button
              theme={props.theme}
              width={280}
              height={25}
              backgroundColor={props.theme.backgroundColorLighter}
              borderColor={props.theme.backgroundColorLight}
              color={props.theme.backgroundColor}
              disabled={saving}
              focusBackgroundColor={props.theme.backgroundColorLight}
              focusBorderColor={props.theme.backgroundColor}
              mouseOverBackgroundColor={props.theme.backgroundColorLight}
              mouseOverBorderColor={props.theme.backgroundColor}
              scrollIntoView={yesChecked || noChecked}
              onClick={async () => {
                setSaving(true);
                try {
                  await save(props.data, yesChecked, noChecked);
                  setSaved(true);
                } catch (error) {
                  console.error(error);
                  if (error instanceof UserFriendlyError) {
                    alert(`Petit problème : ${error.message}`);
                  } else {
                    alert(`Petit problème :(\r\nMerci de réessayer plus tard.`);
                  }
                } finally {
                  setSaving(false);
                }
              }}
            >
              <MaterialIcon
                codePoint={saving ? 0xe5d3 : 0xe163}
                size={24 * 0.75}
              />
              <Span fontWeight={900}>Envoyer la réponse</Span>
            </Button>
          </React.Fragment>
        )}

        {saved && <Paragraph>Merci pour votre réponse.</Paragraph>}
      </Card>
    </div>
  );
}
