import React from "react";

export interface ParagraphProps {
  children?: React.ReactNode;
  color?: string;
  fontFamily?: string;
  fontSize?: string | number;
  fontStyle?: string;
  fontWeight?: number;
}

export function Paragraph(props: ParagraphProps) {
  return (
    <p
      style={{
        color: props.color,
        fontFamily: props.fontFamily,
        fontSize: props.fontSize,
        fontStyle: props.fontStyle,
        fontWeight: props.fontWeight,
      }}
    >
      {props.children}
    </p>
  );
}
